@import "variables.scss";
// This css is for top Signin ang Register block.
.topbar {
  padding: 15px 0;
  font-family: $montserrat-font;
  font-size: $secondary-font;
  font-weight: $fw-700;
  min-height: 50px;
  position: fixed;
  width: 100%;
  z-index: 10;
  top: 0;
  background: $bg-light;
  @media (max-width: 767px) {
    min-height: inherit;
    padding: 5px 0;
  }
  .register-block {
    float: left;
    display: flex;
    align-items: center;
    //margin-top: 8px;
    @media (max-width: 767px) {
      text-align: center;
      width: auto;
      margin-top: 0;
    }
    .login-name-block {
      margin: 8px 0 8px 10px;
      font-weight: $fw-600;
      @media (max-width: 767px) {
        margin: 0 0 0 10px;
      }
      @media (max-width: 560px) {
        font-size: 0.85rem;
      }
    }
    a {
      display: inline-block;
      @media (max-width: 767px) {
        padding: 0 1rem;
      }
    }
  }
  .topbar-menu {
    float: left;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 991px) {
      width: 45%;
    }
    @media (max-width: 767px) {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 15px;
    }
    ul {
      padding: 0;
      margin: 0;
      li {
        float: left;
        margin-right: 25px;
        @media (max-width: 991px) {
          margin-right: 10px;
        }
        a {
          color: $black;
          &:hover,
          &:focus {
            color: $red;
          }
        }
      }
    }
  }
  .currency-block {
    float: left;
    padding-left: 30px;
    @media (max-width: 1200px) {
      padding-left: 0;
    }
  }
  .price-block {
    float: right;
    margin-top: 8px;

    @media (min-width: 767px) and (max-width: 991px) {
      width: 40%;
      text-align: right;
    }
    @media (max-width: 767px) {
      margin-top: 0;
    }
    span {
      @media (max-width: 560px) {
        display: none;
      }
    }
    a {
      color: $black;
      padding: 0 0 0 10px;
      @media (max-width: 767px) {
        padding: 0 1rem;
      }
      &:hover,
      &:focus {
        color: $red;
      }
    }
    .signout-block {
      display: inline-block;
      a {
        color: $red;
        padding-right: 0;
        &:hover,
        &:focus {
          color: $black;
        }
      }
    }
  }
}
.header {
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 10;
  top: 65px;
  border-bottom: 1px solid $gray-light;
  @media (max-width: 767px) {
    top: 34px;
  }
  .head-inner {
    position: relative;
  }
  .logo_block {
    width: auto;
    float: left;
    margin-top: 10px;
    cursor: pointer;
    @media (max-width: 767px) {
      margin-top: 0;
    }
    a {
      font-size: 24px;
      font-weight: $fw-700;
      letter-spacing: 0.1em;
      font-family: $montserrat-font;
      @media (max-width: 560px) {
        font-size: 18px;
      }
    }
    img {
      padding: 10px 0;
      max-width: 70%;
      @media (max-width: 560px) {
        max-width: 50%;
      }
    }
  }
  nav {
    float: left;
    width: 66%;
    padding: 0;
    margin: 20px 0 0 -90px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media (max-width: 1199px) {
      width: 70%;
    }
    @media (max-width: 991px) {
      width: 100%;
      float: right;
      display: block;
      margin: 0;
    }
    .navbar-collapse {
      @media (min-width: 992px) {
        display: block !important;
      }
    }
    .navbar-nav {
      .nav-item {
        .nav-link.active {
          color: $red;
        }
      }
      .nav-link {
        color: $black;
        font-size: 18px;
        font-family: $montserrat-font;
        font-weight: $fw-700;
        padding: 10px 20px;
        @media (max-width: 991px) {
          padding: 10px 0;
          border-bottom: 1px solid $black;
        }
        &:hover {
          color: $red;
        }
      }
      .nav-item:last-child {
        a {
          @media (max-width: 991px) {
            border: none;
            padding-bottom: 50px;
          }
        }
      }
    }
  }
  .head-right {
    width: auto;
    float: right;
    margin-top: 30px;
    @media (max-width: 991px) {
      position: absolute;
      right: 50px;
      margin-top: 45px;
    }
    @media (max-width: 560px) {
      margin-top: 30px;
      right: 45px;
    }
    ul {
      li {
        position: relative;
        float: left;
        margin-right: 15px;
        a {
          color: $black;
          &:hover,
          &:focus {
            color: $red;
          }
          .cart_items {
            position: absolute;
            @include border-radius(50%);
            width: auto;
            height: auto;
            min-width: 20px;
            text-align: center;
            font-size: 12px;
            font-weight: 700;
            color: white;
            padding: 0 8px;
            bottom: 15px;
            right: -10px;
            background-color: $bg-red;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .navbar-toggler {
    border: none;
    padding: 12px 0 0 0;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: -68px;
    @media (max-width: 767px) {
      top: -60px;
    }
    @media (max-width: 560px) {
      top: -55px;
    }
    &:hover,
    &:focus {
      outline: none;
      .toggler-icon {
        background: $red;
        cursor: pointer;
      }
    }
    .toggler-icon {
      width: 20px;
      height: 2px;
      background: $gray-dark;
      display: block;
      margin-bottom: 5px;
    }
  }
  // this css is for close button no need to remove it.
  //	& .collapsed{
  //		.toggler-icon{
  //			&:nth-child(1){
  //				position: absolute;
  //				top: 24px;
  //				-webkit-transform: rotate(135deg);
  //				transform: rotate(135deg);
  //			}
  //			&:nth-child(2){
  //				visibility: hidden;
  //    			background-color: transparent;
  //			}
  //			&:nth-child(3){
  //				position: absolute;
  //				top: 24px;
  //				-webkit-transform: rotate(-135deg);
  //				transform: rotate(-135deg);
  //			}
  //		}
  //	}
}
.search_input {
  input {
    min-width: 0px;
    min-height: 15px;
    z-index: 1000;
    position: absolute;
    right: 7px;
    top: -7px;
  }
}

input {
  outline: none;
}
input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  font-family: inherit;
  font-size: 100%;
}
input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
  display: none;
}

input[type="search"] {
  background: transparent url(../images/search-icon.png) no-repeat 9px center;
  border: transparent;
  padding: 9px 10px 9px 32px;
  width: 55px;
  @include border-radius(10px);
  @include transition(all 0.5s ease);
  &:focus {
    width: 130px;
    background-color: $gray-light;
    border-color: $gray-light;
    -webkit-box-shadow: 0 0 5px $gray-light;
    -moz-box-shadow: 0 0 5px $gray-light;
    box-shadow: 0 0 5px $gray-light;
    @media (max-width: 560px) {
      margin-top: 0px;
    }
  }
}
// input[type="search"]:focus {
//   width: 130px;
//   background-color: #b4b4b4;
//   border-color: #b4b4b4;

//   -webkit-box-shadow: 0 0 5px #b4b4b4;
//   -moz-box-shadow: 0 0 5px #b4b4b4;
//   box-shadow: 0 0 5px #b4b4b4;
//   @media (max-width: 560px) {
//     margin-top: 50px;
//   }
// }

input:-moz-placeholder {
  color: #999;
}
input::-webkit-input-placeholder {
  color: #999;
}

#search_icon input[type="search"] {
  width: 15px;
  padding-left: 10px;
  margin-bottom: 0;
  color: transparent;
  cursor: pointer;
  @media (max-width: 560px) {
    padding-top: 5px;
  }
  &:hover {
    background-color: $white;
  }
  &:focus {
    width: 130px;
    padding-left: 32px;
    color: $gray-dark;
    background-color: $white;
    cursor: auto;
    @media (max-width: 560px) {
      width: 80px;
    }
  }
}
// #search_icon input[type="search"]:hover {
//   background-color: #fff;
// }
// #search_icon input[type="search"]:focus {
//   width: 130px;
//   padding-left: 32px;
//   color: $gray-light;
//   background-color: $white;
//   cursor: auto;
// }
#search_icon input:-moz-placeholder {
  color: transparent;
}
#search_icon input::-webkit-input-placeholder {
  color: transparent;
}
#main {
  margin-top: 148px;
  @media (max-width: 767px) {
    margin-top: 107px;
  }
  @media (max-width: 560px) {
    margin-top: 92px;
  }
}
#search_icon {
  position: relative;
  .search_block {
    position: absolute;
    top: 38px;
    right: 16px;
    width: 160px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    background: #fff;
    padding: 15px;
    text-align: center;
    .searchItem {
      padding: 0;
      margin: 0;
    }
  }
}

/* Bootstrap 4 text input with search icon */
.fa-search {
  background: transparent url(../images/search-icon.png) no-repeat 15px 18px;
  &::before{
    content: "";
  }
}
.has-search .form-control {
  padding-left: 2.375rem;
  margin-bottom: 0;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  margin: 0;
}
