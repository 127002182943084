@import "variables.scss";

.hero-section.categories {
  background-size: cover;
  background-position: center;
  min-height: 500px;
  position: relative;
  .category-list {
    position: relative;
    padding: 50px 30px;
    color: white;
    @media (max-width: 767px) {
      padding: 25px 0px;
    }
    .category-header {
      text-transform: capitalize;
      color: white;
    }
  }
}

.hero-section.categories:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.shop_brand_panel {
  background-color: #ccc;
  .shop_brand_block {
    padding: 60px;
  }
}
.hot_brand_panel {
  padding: 0 0 30px 0;
  .hot_block {
    position: relative;
    -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    background: $white;
    padding: 25px;
    text-align: center;
    .hot {
      position: absolute;
      text-align: left;
    }
  }
}
.product_filter_panel {
  .filter_block {
    padding: 30px 0;
  }
  .filter_items {
    position: relative;
    -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    background: $white;
    padding: 30px;
  }
  .filter_apply {
    display: flex;
    .red {
      padding: 7px;
      margin-left: 20px;
    }
  }
}
.items_block {
  padding-top: 30px;
  .hot_block {
    position: relative;
    -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    background: $white;
    padding: 25px;
    text-align: center;
    .hot {
      position: absolute;
      text-align: left;
    }
  }
  .item {
    margin-bottom: 30px;
    display: inline-block;
  }
  .breadcrumb-sub-cat-name {
    font-size: 18px;
    a {
      font-size: 18px;
      color: $red;
      font-weight: $fw-700;
      &:focus,
      &:hover {
        color: $black;
      }
      cursor: pointer;
    }
  }
}
.category_subscribe {
  padding: 15px;
  .subscribe_for_shop {
    display: flex;
    align-items: center;
    h3 {
      float: left;
      margin: 0;
    }
    .input_search {
      padding-left: 30px;
      float: right;
    }
    input {
      margin-bottom: 0;
      margin-right: 20px;
    }
  }
}
.sub_categories {
  li {
    .sub_categories_submit {
      background-color: transparent;
      border: 0;
      cursor: pointer;
      padding: 0;
      &:hover {
        color: $red;
        .category_name {
          color: $red;
          @include transition(all 0.25s ease);
        }
      }
    }
  }
  .category_name {
    color: $white;
  }
}
.add_to_cart {
  display: flex;
  justify-content: center;
  h3 {
    opacity: 0;
    margin-bottom: 0;
    font-size: 12px;
    transition: all 0.5s;
  }
}
.show {
  display: block !important;
  opacity: 1 !important;
}
