@import "variables.scss";
@import "react-alice-carousel/src/alice-carousel.scss";
img {
  max-width: 100%;
  height: auto;
}
.product-block {
  padding: 20px 15px;
  @media (max-width: 767px) {
    padding: 25px 15px;
  }
}
.product_images_block {
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
}
.breadcrumbs {
  ul {
    padding: 0;
    margin: 0;
    li {
      float: left;
      margin: 0px;
      .arrow {
        margin: 0 5px;
      }
      a {
        color: $gray-dark;
        font-weight: $fw-600;
        &:hover,
        &:focus {
          color: $red;
        }
      }
    }
  }
}
.item-left-block {
  @media (max-width: 767px) {
    display: none;
  }
  .top-image {
    position: relative;
    height: 75%;
    img {
      position: absolute;
      margin: auto;
      left: -100%;
      right: -100%;
      top: -100%;
      bottom: -100%;
      height: 100%;
      width: inherit;
    }
  }

  .colors-selection-block {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    ul {
      padding: 0;
      margin: 0;
      li {
        width: 75px;
        height: 75px;
        float: left;
        margin-right: 15px;
        img {
          height: 100%;
        }
      }
      & :last-child {
        margin-right: 0;
      }
      & .active {
        border: 1px solid $red;
      }
    }
  }
}
.item-right-block {
  @media (max-width: 767px) {
    margin-top: 0px;
  }

  .item-title {
    margin-right: 50px;
  }
  .amount-block {
    font-weight: $fw-700;
    position: relative;
    color: $red;
    font-size: 24px;
    display: flex;
    h6.pricetag {
      color: $black;
      padding-right: 8px;
      margin-top: 0.32rem;
    }
    p {
      border-bottom: 3px solid black;
      width: 60px;
      position: absolute;
      top: 28px;
      left: 72px;
    }
  }
  .quantity-block {
    ul {
      padding: 0;
      margin: 0;
      li {
        float: left;
        margin-right: 15px;
        background: #efefef;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include border-radius(50%);
        a {
          color: $black;
          font-weight: $fw-700;
          font-size: 24px;
        }
      }
      & .number-block {
        padding: 15px;
        width: auto;
        min-width: 80px;
        font-weight: $fw-700;
        font-size: 20px;
        background: $white;
        border: 1px solid #efefef;
        @include border-radius(20px);
      }
    }
  }
  .btn-block {
    @media (max-width: 560px) {
      text-align: center;
      padding-top: 0.1rem !important;
    }
    .add_to_cart {
      display: block;
      margin-left: 45px;
    }
  }
  .wish-list {
    font-weight: $fw-500;
    margin: 10px 50px;
    font-size: 14px;
    float: right;
    @media (max-width: 992px) {
      margin: 10px 0;
    }
    @media (max-width: 560px) {
      float: left;
      text-align: center;
      width: 100%;
    }
    a {
      color: $gray-dark;
      span {
        color: $red;
        margin-right: 5px;
        font-size: 24px;
        font-weight: $fw-700;
        position: relative;
        top: 3px;
      }
      &:hover,
      &:focus {
        color: $red;
      }
    }
  }
}

// Reviews Section Styles

.review-block {
  border-top: 2px solid #e5e5e5;
  border-bottom: 2px solid #e5e5e5;
  @media (max-width: 767px) {
    padding: 0 15px;
  }
  .author-block {
    color: $black;
    h6 {
      margin-bottom: 0;
    }
    span {
      font-size: 12px;
      color: $gray-dark;
    }
  }
  .starts-block {
    display: inline-block;
  }
  .comment-block {
    padding: 0;
    margin: 0;
    li {
      float: left;
      margin-right: 30px;
      display: flex;
      align-items: center;
      span {
        background: $gray-light;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        color: $red;
        font-size: $heading2;
        @include border-radius(50%);
      }
      &:last-child {
        span {
          font-size: $base-font;
        }
      }
    }
  }
}
form {
  .form-group {
    margin-bottom: 0;
    label {
      float: left;
      color: $black;
      font-weight: $fw-700;
      @media (max-width: 767px) {
        width: 100%;
      }
    }
    span {
      width: auto;
      margin-left: 25%;
      margin-top: -25px;
      font-size: 12px;
      float: left;
      position: relative;
      z-index: 1;
      @media (max-width: 767px) {
        margin-left: 0;
      }
    }
  }
}
.product-image-block {
  text-align: center;
  padding: 30px 20px;
  box-shadow: 0 2px 3px -2px black;
  border: 1px solid #9e9e9e;
  @media (max-width: 991px) {
    margin-bottom: 30px;
  }
  .price {
    color: $red;
    font-size: 20px;
    font-weight: $fw-700;
  }
  &:hover {
    box-shadow: 0px 10px 50px -5px $black;
  }
}
.addtocart_btn {
  color: $white;
  &:hover {
    color: $black;
  }
}
.productlocations_breadcrumb {
  display: inline-flex;
  h6 {
    margin-top: 0.2rem;
  }
}
.mb_price {
  margin-bottom: 132px;
}
.clip_loader {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.carousel {
  margin: 0 60px;
}
.productrecommendations_item {
  margin: 0 15px;
  &:first-child {
    margin-left: 0;
    @media (max-width: 767px) {
      margin-left: 15px;
    }
  }
}
