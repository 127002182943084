@import "variables.scss";
.home {
  min-height: 200px;
}
.header_panel {
  background-color: $gray-dark;
  padding: 90px;
  width: 100%;
  margin: 0;
  h1,
  h2 {
    color: $white;
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 600px) {
    padding: 15px;
  }
}
.shop_now_panel {
  padding: 30px 15px;
  @media only screen and (max-width: 600px) {
    padding: 15px;
  }
  .product_panel {
    padding: 30px;
    position: relative;
    -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    background: $white;
    .product_img {
      padding: 0 30px 0px 0px;
      @media only screen and (max-width: 600px) {
        padding: 0 15px 0px 0px;
      }
    }
    .shop_now {
      margin: auto;
      padding: 0px 8%;
      h2,
      h3 {
        margin-bottom: 25px;
      }
      @media only screen and (max-width: 600px) {
        padding: 25px 0;
        text-align: center;
      }
    }
    .sale {
      position: absolute;
    }
  }
}
.register_panel {
  .shop_now_panel {
    padding: 0px;
  }
  .wow_block,
  .wow_men_block {
    width: 100%;
    min-height: 345px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: $bg-teal;
    @media only screen and (max-width: 600px) {
      margin-bottom: 30px;
    }
  }
  .wow_block {
    margin-bottom: 30px;
    h1 {
      padding-bottom: 20px;
    }
  }
  .game_begin_block {
    position: relative;
    -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    background: $white;
    .game_sub_block {
      padding: 32px;
      text-align: center;
      margin-bottom: 10px;
    }
    .pop {
      position: absolute;
      top: 25px;
      left: 25px;
    }
  }
}
.subscribe_panel {
  background-color: $gray-light;
  margin-top: 30px;
  padding: 45px;
  .subscribe_block {
    text-align: center;
    .search {
      margin-right: 15px;
    }
    .fa.fa-envelope {
      position: relative;
      left: 25px;
      @media only screen and (max-width: 600px) {
        left: -142px;
        top: 37px;
      }
    }
  }
}

img {
  width: 100%;
}
.display_none {
  display: none;
}
