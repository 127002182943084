@import "variables.scss";
.cart-block {
  padding: 50px 15px 25px;
  @media (max-width: 560px) {
    padding-top: 15px;
  }
  .cart-top-block {
    @media (max-width: 560px) {
      display: none;
    }
  }
  .cart-top-block,
  .cart-single-block {
    color: $gray-light;
    font-weight: $fw-700;
    font-family: $montserrat-font;
    font-size: 14px;
    border-bottom: 1px solid $gray-light;
    > ul {
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 560px) {
        display: inline-block;
      }
      li {
        float: left;
        &:nth-child(1) {
          width: 60%;
          padding-right: 15px;
          @media (max-width: 992px) {
            width: 50%;
          }
          @media (max-width: 767px) {
            width: 50%;
          }
          @media (max-width: 560px) {
            width: 100%;
            padding-right: 0;
            margin-bottom: 15px;
          }
        }
        &:nth-child(2) {
          width: 10%;
          text-align: left;
          color: $gray-light;
          font-weight: $fw-700;
          @media (max-width: 560px) {
            margin-top: 5px;
          }
        }
        &:nth-child(3) {
          width: 25%;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          @media (max-width: 560px) {
            width: 60%;
            justify-content: flex-end;
          }
        }
        &:nth-child(4) {
          width: 10%;
          text-align: right;
          @media (max-width: 560px) {
            margin-top: 5px;
            width: auto;
            float: right;
          }
        }
      }
    }
  }
  .cart-single-block {
    border-bottom: none;
    font-weight: normal;
    color: $black;
    margin-top: 30px;
    margin-bottom: 30px;
    .img-block {
      img {
        float: left;
        width: 100px;
        height: 100px;
        padding: 20px;
        margin-right: 30px;
        border: 1px solid $gray-light;
        @media (max-width: 992px) {
          float: left;
          width: 80px;
          height: 80px;
          padding: 10px;
        }
        @media (max-width: 560px) {
          margin-right: 10px;
        }
      }
      span {
        @media (max-width: 560px) {
          display: table-cell;
        }
        p {
          margin-bottom: 0;
        }
        .remove {
          a {
            font-size: 10px;
            margin-top: 5px;
            color: $black;
            span {
              color: $red;
            }
          }
        }
      }
    }
    .quantity-block {
      span {
        float: left;
        a {
          float: left;
          margin-right: 15px;
          background: #efefef;
          color: $black;
          width: 25px;
          height: 25px;
          font-size: 18px;
          font-weight: $fw-700;
          display: flex;
          align-items: center;
          justify-content: center;
          @include border-radius(50%);
          &:hover,
          &:focus {
            color: $red;
          }
          @media (max-width: 560px) {
            margin-right: 5px;
          }
        }
      }
      & .number-block {
        padding: 10px;
        width: auto;
        min-width: 70px;
        margin-right: 15px;
        font-weight: $fw-700;
        font-size: 20px;
        background: $white;
        border: 1px solid #efefef;
        @include border-radius(20px);
        @media (max-width: 992px) {
          min-width: 50px;
        }
        @media (max-width: 560px) {
          min-width: 35px;
          margin-right: 5px;
          padding: 5px;
          @include border-radius(10px);
        }
      }
    }
    .price {
      font-weight: $fw-700;
      font-size: 22px;
      color: $black;
    }
  }
}
.cart-bottom-block {
  background: #efefef;
  padding: 20px 15px;
  @media (max-width: 360px) {
    text-align: center;
  }
  a,
  button {
    &:last-child {
      float: right;
      @media (max-width: 360px) {
        float: none;
        display: inherit;
        margin: 15px auto;
      }
    }
  }
}
.react-confirm-alert-body-element {
  .react-confirm-alert-button-group > button {
    background: $red;
    font-size: 1rem;
    padding: 0.5rem 1.5rem;
    border: 1px solid $red;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    @include transition(all 0.25s ease);
    @include border-radius(20px);
    &:hover {
      background: $white;
      color: $black;
      border: 1px solid $red;
    }
  }
}
