@import "variables.scss";
footer {
  padding: 50px 0;
  ul {
    li {
      font-size: 20px;
      margin-bottom: 10px;
      @media (max-width: 767px) {
        font-size: $base-font;
      }
      a {
        color: $black;
        font-size: $base-font;

        &:hover,
        &:focus {
          color: $red;
        }
      }
    }
  }
}
.developed_logo {
  li {
    img {
      max-width: 60%;
      cursor: pointer;
    }
  }
}
