@import "variables.scss";
.signin-form {
  input {
    width: 100%;
  }
  .radio-checkbox-block {
    input[type="checkbox"] {
      float: left;
      width: auto;
      min-width: inherit;
      min-height: inherit;
      cursor: pointer;
      //margin-right: 15px;
    }
    span {
      cursor: pointer;
    }
  }
}
/* Facebook */
.facebook {
  background-color: #4c69ba;
  background-image: linear-gradient(#4c69ba, #3b55a0);
  text-shadow: 0 -1px 0 #354c8c;
  position: relative;
  padding: 5px 15px 5px 45px;
  margin-bottom: 15px;
  display: inline-block;
  width: 150px;
  color: $white;
  @include border-radius(5px);
  &:hover,
  &:focus {
    color: $white;
  }
}
.facebook:before {
  border-right: #364e92 1px solid;
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png")
    6px 6px no-repeat;
  content: "";
  position: absolute;
  left: 0px;
  top: 1px;
  width: 35px;
  height: 30px;
}
.facebook:hover,
.facebook:focus {
  background-color: #5b7bd5;
  background-image: linear-gradient(#5b7bd5, #4864b1);
}

/* Google */
.google {
  background: #dd4b39;
  margin-right: 20px;
  padding: 5px 15px 5px 45px;
  margin-bottom: 15px;
  display: inline-block;
  width: 150px;
  color: $white;
  @include border-radius(5px);
  &:hover,
  &:focus {
    color: $white;
  }
}
.google:before {
  content: "";
  border-right: #bb3f30 1px solid;
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png")
    6px 6px no-repeat;
  position: absolute;
  left: 15px;
  top: 1px;
  width: 35px;
  height: 30px;
}
.google:hover,
.google:focus {
  background: #e74b37;
}
