@import "variables.scss";

.checkout_information {
  margin-bottom: 60px;
  margin-top: 60px;
  display: inline-block;
  .checkout_block {
    padding: 60px;
    //margin-top: 60px;
    @media (max-width: 767px) {
      padding: 15px;
    }
    .progress-txt {
      display: flex;
      @media (max-width: 767px) {
        display: none;
      }
      li {
        width: 100%;
        padding-right: 0;
        &:last-child {
          width: 27%;
        }
      }
    }
  }
  background: #fff;
  position: relative;
  -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  background: $white;
  .success-block {
    width: 100%;
    padding-top: 50px;
    @media (max-width: 767px) {
      padding: 15px;
    }
  }
  ul {
    padding-top: 20px;
    li {
      display: inline;
      list-style-type: none;
      padding-right: 16%;
      float: left;
    }
    .bar {
      position: relative;
      margin: 0 5px 30px;
      border-radius: 0;
      content: "";
      background: #b4b4b4;
      left: 0px;
      top: 6px;
      width: 28%;
      height: 8px;
      @media (max-width: 992px) {
        width: 26%;
      }
      @media (max-width: 767px) {
        width: 24.5%;
      }
      @media (max-width: 480px) {
        width: 17%;
      }
    }
    .dot {
      background: #b4b4b4;
      padding-right: 0;
      margin: 0;
    }
  }
}
.active_next {
  background: $red !important;
}
.checkout_next {
  //background-color: #efefef;
  padding: 25px 0px;
  width: 100%;
  @media (max-width: 767px) {
    padding: 15px;
  }
  .back {
    margin-left: 0px;
    @media (max-width: 767px) {
      margin-left: 0;
    }
  }
  .next_step {
    float: right;
    margin-right: 0px;
    @media (max-width: 767px) {
      margin-right: 0;
    }
  }
}
.form-check {
  padding: 0px;
  label {
    display: block;
  }
  .address {
    word-break: break-word;
  }
}
.form-check-input {
  position: relative;
  margin: 0 20px 0 0;
  min-width: 24px;
  font-size: 10px;
  top: 20px;
}
.delivery_options,
.country {
  margin: 0;
  h3 {
    display: inline-block;
    padding: 0 10px;
  }
}
.form-group.delivery_options {
  border-top: 1px solid $gray-light;
  padding-top: 30px;
}
.delivery_block,
.conformation_block {
  padding-top: 45px;
  width: 100%;
}
.delivery_block {
  .form-group {
    input {
      min-width: 40px;
      @media (max-width: 767px) {
        width: 100%;
      }
    }
    .radio-checkbox-block {
      label {
        display: block;
        margin-left: 30px;
      }
    }
    select {
      width: 48%;
      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }
}

table.order_summery {
  border-collapse: collapse;
  width: 100%;
  th,
  td {
    padding: 8px;
    text-align: left;
  }
  tr:hover {
    background-color: #f5f5f5;
  }
}
.payment_block {
  padding: 25px 0;
  .validthru {
    min-width: 50px;
  }
  .paypal {
    width: 50%;
    margin: 30px 0;
    border: 1px solid;
    .radio-checkbox-block {
      justify-content: center;
    }
    .paypal_img {
      img {
        padding-top: 25px;
        width: 25%;
        height: 25%;
      }
      display: flex;
      justify-content: center;
    }
  }
  .cvv_text {
    padding-top: 30px;
  }
}
.StripeElement {
  min-height: 50px;
  margin-bottom: 25px;
  display: grid;
  align-items: center;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.address_select_block {
  select {
    width: 48%;
  }
}
.save_address {
  margin-right: 20px;
}
.address_block {
  .item {
    display: grid;
  }
  .hot_block {
    text-align: left;
    padding: 0;
    display: grid;
  }
  .address {
    padding: 25px;
    padding-bottom: 0;
    cursor: pointer;
    .fa-plus {
      font-size: 60px;
      color: #decdcd;
      padding: 24px;
    }
    &:hover {
      background-color: $gray-light;
      opacity: 0.75;
    }
  }
  .default {
    border: 2px solid #f62f5e;
  }

  .next_step {
    padding: 25px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
}
.active_stage {
  color: $red;
}
.modal-open {
  .modal-backdrop.show {
    background-color: rgba(0, 0, 0, 0.75);
  }
  .edit-address-popup {
    .form-content {
      padding: 20px;
    }
    input.form-control {
      margin-bottom: 0;
      min-height: 36px;
    }
    .form-check,
    .selectpicker {
      margin: 0;
    }
  }
}
.add-address-block {
  .hot_block {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dotted;
  }
}
